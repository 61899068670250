<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <div class="container">
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="/work1">每日班表更新</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work2">資料更新</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work3">每日推薦更新</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work4">單筆新增</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work5">其他功能</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work6">TG轉換</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work8">客評審核</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/work9">客評複製</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
