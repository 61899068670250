import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/work1',
    name: 'work1',
    component: () => import('../views/WorkView.vue'),
    meta: { title: '每日班表更新'}
  },
  {
    path: '/work2',
    name: 'work2',
    component: () => import('../views/WorkView2.vue'),
    meta: { title: '資料更新'}
  },
  {
    path: '/work3',
    name: 'work3',
    component: () => import('../views/WorkView3.vue'),
    meta: { title: '每日推薦更新'}
  },
  {
    path: '/work4',
    name: 'work4',
    component: () => import('../views/WorkView4.vue'),
    meta: { title: '單筆新增'}
  },
  {
    path: '/work5',
    name: 'work5',
    component: () => import('../views/WorkView5.vue'),
    meta: { title: '其他功能'}
  },
  {
    path: '/work6',
    name: 'work6',
    component: () => import('../views/WorkView6.vue'),
    meta: { title: 'TG轉換'}
  },
  {
    path: '/work7',
    name: 'work7',
    component: () => import('../views/WorkView7.vue'),
    meta: { title: 'Excel上傳'}
  },
  {
    path: '/work8',
    name: 'work8',
    component: () => import('../views/WorkView8.vue'),
    meta: { title: '客評審核'}
  },
  {
    path: '/work9',
    name: 'work9',
    component: () => import('../views/WorkView9.vue'),
    meta: { title: '客評複製'}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
